import store from "../store";

const routes = [
  {
    path: "/",
    component: () => import("../components/Landing.vue"),
  },
  {
    path: "/faq",
    component: () => import("../components/FAQ"),
  },
  // this password-reset is only for referred realtor whose information was filled
  // by other realtor. so the referred realtor comes and create his own password
  {
    path: "/password-reset",
    component: () => import("../components/PasswordReset.vue"),
  },
  {
    path: "/password-reset/*",
    component: () => import("../components/PasswordReset.vue"),
  },
  // recoverpassword is for all realtor who forgot their password
  {
    path: "/recoverpassword/*",
    component: () => import("../components/RecoverPassword.vue"),
  },
  {
    path: "/promo",
    // component: () => import("../components/PromoNew.vue")
    component: () => import("../components/PromoNew.vue"),
  },
  {
    path: "/user",
    component: () => import("../components/Layout/DashboardLayout.vue"),
    beforeEnter(to, from, next) {
      if (store.getters.isAuthenticated) {
        next();
      } else {
        next("/");
      }
    },
    children: [
      // {
      //   path: "",
      //   component: () => import("../components/Dashboard2.vue")
      // },
      {
        path: "",
        name: "AssociateDashboard",
        component: () => import("../components/AssociateDashboard.vue"),
      },
      {
        path: "dashboard",
        name: "AssociateDashboard",
        component: () => import("../components/AssociateDashboard.vue"),
      },
      // {
      //   path: "dashboard",
      //   component: () => import("../components/Dashboard2.vue")
      // },
      // {
      //   path: "new-dashboard",
      //   component: () => import("../components/Dashboard2.vue")
      // },
      {
        path: "dashboard/wallet",
        component: () => import("../components/Wallet.vue"),
      },
      {
        path: "dashboard/withdraw",
        component: () => import("../components/WithDraw.vue"),
      },
      {
        path: "dashboard/transactions",
        component: () => import("../components/Transactions.vue"),
      },
      {
        path: "dashboard/point-logs",
        component: () => import("../components/PointLogs.vue"),
      },
      {
        path: "account",
        component: () => import("../components/Account.vue"),
      },
      {
        path: "recruit-realtor",
        component: () => import("../components/RecruitRealtor.vue"),
      },
      {
        path: "feedback",
        component: () => import("../components/Feedback.vue"),
      },
      {
        path: "lead-directory",
        component: () => import("../components/LeadDirectory.vue"),
      },
      {
        path: "identifier",
        component: () => import("../pages/PropertyIdentifier.vue"),
      },
      {
        path: "smart-guarantor",
        component: () => import("../pages/SmartGuarantor.vue"),
      },
      {
        path: "help-center",
        component: () => import("../components/HelpCenter.vue"),
      },
      {
        path: "properties",
        component: () => import("../components/Properties.vue"),
      },
      {
        path: "profile",
        component: () => import("../components/Profile.vue"),
      },

      // {
      //   path: "promo",
      //   component: () => import("../components/Promo.vue")
      // },
      {
        path: "properties-landlord",
        component: () => import("../components/RegisterPropertyForm.vue"),
      },
      {
        path: "plans-billings",
        component: () => import("../components/PlansBillings.vue"),
      },
      {
        path: "register-tenant",
        component: () => import("../pages/RegisterTenantView.vue"),
      },
      {
        path: "register-landlord",
        name: "AddLandlordProperty",
        component: () => import("../components/AddLandlordProperty.vue"),
      },

      {
        path: "landlords",
        name: "LandlordLists",
        component: () => import("../components/LandlordLists.vue"),
      },
      {
        path: "addpropertytolandlord/:id",
        name: "AddPropertyLandlord",
        component: () => import("../components/AddPropertyLandlord.vue"),
      },
      {
        path: "landlord-details/:id",
        name: "LandlordPropertyViewEdit",
        component: () => import("../components/LandlordPropertyViewEdit.vue"),
      },
      {
        path: "add-unit/:id",
        name: "AddPropertyUnit",
        component: () => import("../components/AddPropertyUnit.vue"),
      },
      {
        path: "opportunity-more/:id",
        name: "opportunityDeskView",
        component: () =>
          import("../pages/opportunityDesk/opportunityDeskView.vue"),
      },
      {
        path: "opportunity-property/:id",
        name: "propertyDetails",
        component: () => import("../pages/opportunityDesk/propertyDetails.vue"),
      },
      {
        path: "smart-agent",
        name: "SmartAgent",
        component: () => import("../components/SmartAgent.vue"),
      },
      {
        path: "agent-add-property",
        name: "AddProperty",
        component: () => import("../smartAgents/AgentAddProperty.vue"),
      },
      {
        path: "agent-property-details/:id",
        name: "PropertyDetails",
        component: () => import("../smartAgents/PropertyDetails.vue"),
      },
      {
        path: "agent-profile-overview",
        name: "SmartAgentProfile",
        component: () => import("../smartAgents/SmartAgentProfile.vue"),
      },

      {
        path: "profile-settings",
        name: "SmartAgentProfile",
        component: () => import("../AccountSettings/SettingsHome.vue"),
      },

      {
        path: "voucher",
        name: "VoucherHome",
        component: () => import("../Voucher/VoucherHome.vue"),
      },
      {
        path: "voucher-options/:type",
        name: "OptionsType",
        component: () => import("../Voucher/OptionsType.vue"),
      },
      {
        path: "my-vouchers",
        name: "VoucherLists",
        component: () => import("../Voucher/VoucherLists.vue"),
      },
      {
        path: "bulk-vouchers-requests",
        name: "BulkRequest",
        component: () => import("../Voucher/BulkRequest.vue")
      },
      {
        path: "partner/dashboard",
        name: "Dashboard",
        component: () => import("../Partners/Dashboard.vue")
      },
      {
        path: "partner/property-manaegement",
        name: "PropertyManagement",
        component: () => import("../Partners/PropertyManagement.vue")
      },
      {
        path: "smart-lock",
        name: "Dashboard",
        component: () => import("../smartAgents/SmartLock.vue")
      },
      {
        path: "bulk-vouchers-requests",
        name: "BulkRequest",
        component: () => import("../Voucher/BulkRequest.vue")
      },
      {
        path: "partner/dashboard",
        name: "Dashboard",
        component: () => import("../Partners/Dashboard.vue")
      },
      {
        path: "partner/property-manaegement",
        name: "PropertyManagement",
        component: () => import("../Partners/PropertyManagement.vue")
      },
      {
        path: "partner/property-details",
        name: "PropertyDetails",
        component: () => import("../Partners/PropertyDetails.vue")
      },
      {
        path: "smart-lock",
        name: "Dashboard",
        component: () => import("../smartAgents/SmartLock.vue")
      },

      // {
      //   path: "vendors/home",
      //   component: () => import("../components/VendorsHome.vue"),
      // },
      
      {
        path: "vendors/state-bonus",
        name: "StateBonus",
        component: () => import("../components/StateBonus.vue")
      },
      {
        path: "vendors/profile",
        component: () => import("../components/VendorsProfile.vue"),
      },
      {
        path: "vendors/profile-setup",
        component: () => import("../components/VendorProfileSetup.vue"),
      },
      //PROMO IS OVER. NOT NEEDED
      // {
      //   path: "leaderboard",
      //   component: () => import("../components/Leaderboards")
      // }
    ],
  },
  {
    // this is meant to match any realtor's referral link. this should be placed last as to ensure
    // that it has checked all other route and it doesnt match. the realtor/user code starts with r
    // so whenever it doesnt start with r anymore then this path rule must also be changed.
    path: "/r*",
    component: () => import("../components/Landing.vue"),
  },
];

export default routes;
